$theme-red: #56B7F7 !default;

.react-confirm-alert-overlay {
    z-index: 101;
    background: rgba(0,0,0,.42);
}

.react-confirm-alert-button-group > button {
    background-color: $theme-red;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    min-width: 100px;
    border: solid 1px transparent;

    > button{

        &:hover {
            border-color: $theme-red;
            background-color: #fff;
            color: $theme-red;
        }

        &:nth-child(2) {
            border: solid 1px $theme-red;
            background-color: #fff;
            color: $theme-red;

            &:hover {
                background-color: $theme-red;
                color: #fff;
            }
        }
    }
}

.react-confirm-alert-body {
    color: #292c2e;
    border-radius: 5px;
    width: auto;
    max-width: 450px;
    min-width: 320px;
    margin:  0 20px;

        > h1 {
        color: #292c2e;
        font-size: 30px;
        margin:  0 0 11px;
        font-weight: 600;
    }
}

body.react-confirm-alert-body-element {
    overflow: auto;
}
