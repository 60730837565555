[class^="icon-"] {
  font-family: "ilmx" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class*="icon-"] {
  font-family: "ilmx" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-Wrong {
  .path1 {
    &:before {
      content: "\e900";
      color: rgb(255, 122, 0);
    }
  }
  .path2 {
    &:before {
      content: "\e901";
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
}
.icon-Globeb {
  &:before {
    content: "\e902";
  }
}
.icon-Check-Circle {
  &:before {
    content: "\e903";
  }
}
.icon-Check {
  &:before {
    content: "\e904";
  }
}
.icon-Chevron-Double-Left {
  &:before {
    content: "\e905";
  }
}
.icon-Chevron-Double-Right {
  &:before {
    content: "\e906";
  }
}
.icon-Chevron-Down {
  &:before {
    content: "\e907";
  }
}
.icon-Chevron-Left {
  &:before {
    content: "\e908";
  }
}
.icon-Chevron-Right {
  &:before {
    content: "\e909";
  }
}
.icon-Chevron-Up {
  &:before {
    content: "\e90a";
  }
}
.icon-Circle {
  &:before {
    content: "\e90b";
  }
}
.icon-Clock {
  &:before {
    content: "\e90c";
  }
}
.icon-Copy-Outline-Color {
  &:before {
    content: "\e90d";
  }
}
.icon-Correct {
  .path1 {
    &:before {
      content: "\e90e";
      color: rgb(41, 203, 151);
    }
  }
  .path2 {
    &:before {
      content: "\e90f";
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
}
.icon-Discussion {
  &:before {
    content: "\e910";
  }
}
.icon-Ellipses {
  &:before {
    content: "\e912";
  }
}
.icon-External-Link-Outline-Color {
  &:before {
    content: "\e913";
  }
}
.icon-Filter {
  &:before {
    content: "\e914";
  }
}
.icon-Flag-2-Outline-Color {
  &:before {
    content: "\e915";
  }
}
.icon-Flash {
  &:before {
    content: "\e916";
  }
}
.icon-Graded {
  &:before {
    content: "\e917";
  }
}
.icon-Half-Star-Outline-Color-1 {
  &:before {
    content: "\e918";
  }
}
.icon-Half-Star-Outline-Color {
  &:before {
    content: "\e919";
  }
}
.icon-Help-Outline {
  &:before {
    content: "\e91a";
  }
}
.icon-Home-22 {
  &:before {
    content: "\e91b";
  }
}
.icon-Invisible-Outline-Color {
  &:before {
    content: "\e91c";
  }
}
.icon-Key {
  &:before {
    content: "\e91d";
  }
}
.icon-Language-v2 {
  &:before {
    content: "\e91e";
  }
}
.icon-Link-4 {
  &:before {
    content: "\e920";
  }
}
.icon-Loading-4 {
  &:before {
    content: "\e921";
  }
}
.icon-Loading-1 {
  &:before {
    content: "\e922";
  }
}
.icon-Loading {
  &:before {
    content: "\e923";
  }
}
.icon-Lock-2-Outline-Color {
  &:before {
    content: "\e924";
  }
}
.icon-Login {
  &:before {
    content: "\e925";
  }
}
.icon-Logout {
  &:before {
    content: "\e926";
  }
}
.icon-Mail-Open-Outline-Color {
  &:before {
    content: "\e927";
  }
}
.icon-Mail-Outline-Color {
  &:before {
    content: "\e928";
  }
}
.icon-Microlearning-v1 {
  &:before {
    content: "\e929";
  }
}
.icon-Notification-8 {
  &:before {
    content: "\e92a";
  }
}
.icon-Notification-11 {
  &:before {
    content: "\e92b";
  }
}
.icon-Notification-Slash-4 {
  &:before {
    content: "\e92c";
  }
}
.icon-Overview-menu {
  &:before {
    content: "\e92d";
  }
}
.icon-Pen-2-Outline-Color {
  &:before {
    content: "\e92e";
  }
}
.icon-Pin-1 {
  &:before {
    content: "\e92f";
  }
}
.icon-Pin {
  &:before {
    content: "\e930";
  }
}
.icon-Prerequesites {
  &:before {
    content: "\e931";
  }
}
.icon-Profile-Add-Outline-Color {
  &:before {
    content: "\e932";
  }
}
.icon-Profile-Edit-Outline-Color {
  &:before {
    content: "\e933";
  }
}
.icon-Profile-Outline-Color {
  &:before {
    content: "\e934";
  }
}
.icon-Reading {
  &:before {
    content: "\e936";
  }
}
.icon-Remove {
  &:before {
    content: "\e937";
  }
}
.icon-Search-2-Outline-Color {
  &:before {
    content: "\e938";
  }
}
.icon-Settings {
  &:before {
    content: "\e939";
  }
}
.icon-Slide {
  &:before {
    content: "\e93a";
  }
}
.icon-Star-Outline-Color-1 {
  &:before {
    content: "\e93b";
  }
}
.icon-Star-Outline-Color {
  &:before {
    content: "\e93c";
  }
}
.icon-Team-Outline-Color {
  &:before {
    content: "\e93d";
  }
}
.icon-Trending-Down {
  &:before {
    content: "\e93e";
  }
}
.icon-Trending-Up {
  &:before {
    content: "\e93f";
  }
}
.icon-Unlock-2-Outline-Color {
  &:before {
    content: "\e941";
  }
}
.icon-Video-course {
  &:before {
    content: "\e942";
  }
}
.icon-Video-v2 {
  &:before {
    content: "\e943";
  }
}
.icon-Video-1 {
  &:before {
    content: "\e944";
  }
}
.icon-Video {
  &:before {
    content: "\e945";
  }
}
.icon-Visible-Outline-Color {
  &:before {
    content: "\e946";
  }
}
.icon-Warning-2-Outline {
  &:before {
    content: "\e947";
  }
}
.icon-Warning-Outline {
  &:before {
    content: "\e948";
  }
}
.icon-Filled {
  &:before {
    content: "\e949";
  }
}
.icon-Add {
  &:before {
    content: "\e94a";
  }
}
.icon-Arrow-Down {
  &:before {
    content: "\e94b";
  }
}
.icon-Arrow-Left {
  &:before {
    content: "\e94c";
  }
}
.icon-Arrow-Right {
  &:before {
    content: "\e94d";
  }
}
.icon-Arrow-Up {
  &:before {
    content: "\e94e";
  }
}
.icon-Assessment {
  &:before {
    content: "\e94f";
  }
}
.icon-Award-Badge {
  &:before {
    content: "\e950";
  }
}
.icon-Bin-Normal-Outline-Color {
  &:before {
    content: "\e951";
  }
}
.icon-Book-Open {
  &:before {
    content: "\e952";
  }
}
.icon-Book {
  &:before {
    content: "\e953";
  }
}
.icon-Bookmark-Outline-Color-1 {
  &:before {
    content: "\e954";
  }
}
.icon-Bookmark-Outline-Color {
  &:before {
    content: "\e955";
  }
}
.icon-Bulb {
  &:before {
    content: "\e956";
  }
}
.icon-Calendar {
  &:before {
    content: "\e957";
  }
}
.icon-Cancel {
  &:before {
    content: "\e958";
  }
}
.icon-Caret-Down-Outline {
  &:before {
    content: "\e959";
  }
}
.icon-Caret-Left-Outline {
  &:before {
    content: "\e95a";
  }
}
.icon-Caret-Right-Outline {
  &:before {
    content: "\e95b";
  }
}
.icon-Caret-Up-Outline {
  &:before {
    content: "\e95c";
  }
}
.icon-Horizontal-Sort-Arrow {
  &:before {
    content: "\e911";
    color: "#70787e";
  }
}
.icon-Progress {
  &:before {
    content: "\e91f";
    color: #70787e;
  }
}
.icon-Play-32 {
  &:before {
    content: "\e935";
    color: "#70787e";
  }
}
.icon-Menu {
  &:before {
    content: "\e940";
    color: "#70787e";
  }
}
.icon-Like-Outline-Color-01 {
  &:before {
    content: "\e95d";
    color: "#70787e";
  }
}
.icon-Download-Outline-Color {
  &:before {
    content: "\e95e";
    color: "#70787e";
  }
}
.icon-Dislike-Outline-01 {
  &:before {
    content: "\e95f";
    color: "#70787e";
  }
}
icon-Draft {
  &:before {
    content: "\e966";
    color: "#70787e";
  }
}
.icon-Apps {
  &:before {
    content: "\e965";
    color: "#70787e";
  }
}
.icon-Block-Outline {
  &:before {
    content: "\e964";
    color: "#70787e";
  }
}
.icon-Bookmark-Outline-Color1 {
  &:before {
    content: "\e963";
    color: "#70787e";
  }
}
.icon-Enroll {
  &:before {
    content: "\e962";
    color: "#70787e";
  }
}
.icon-Profile-Cancel-Outline-Color {
  &:before {
    content: "\e961";
    color: "#70787e";
  }
}
.icon-Wand-Outline-Color {
  &:before {
    content: "\e960";
    color: "#70787e";
  }
}