@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/modal";
@import "assets/styles/select-search";
@import "assets/styles/custom";
@import "assets/styles/ilmx-font";
@import "~bootstrap/scss/tooltip";

$body-font: hkgrotesk, Helvetica, Arial, sans-serif !default;

.user-popup {
    .modal-dialog {
        max-width: 640px;
    }

    .modal-content {
        padding: 0;
        border-radius: 0;
        background: none;
        border: 0;
    }
}

.tooltip {
    margin-top: 1px;
    z-index: 90;
    &.show {
        opacity: 1;
    }
    .arrow {
        &:before {
            border-bottom-color: #aaa;
        }
        &:after {
            position: absolute;
            content: "";
            border-color: transparent;
            border-style: solid;
            bottom: 0;
            border-width: 0 0.4rem 0.4rem;
            border-bottom-color: #ecf0f4;
            bottom: -1px;
            z-index: 1;
        }
    }
    .tooltip-inner {
        background: #ecf0f4;
        border: 1px solid #aaa;
        border-radius: 2px;
        padding: 13px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color: #000;
        width: 252px;
        max-width: inherit;
        text-align: left;
        font-family: $body-font;
    }
}
