/**
 * Main wrapper
 */
 $primary: #56B7F7;
 $white: #fff;
.select-search {
    width: 300px;
    position: relative;
    box-sizing: border-box;
    * {
        box-sizing: inherit;
        &::after {
            box-sizing: inherit;
        }
        &::before {
            box-sizing: inherit;
        }
    }
    &:not(.is-disabled) {
        .select-search__input {
            cursor: pointer;
        }
    }
    &:not(.is-loading) {
        &:not(.select-search--multiple) {
            .select-search__value {
                &::after {
                    position: absolute;
                    right: 17px;
                    top: 17px;
                    content: '';
                    width: 8px;
                    height: 8px;
                    border: solid 2px #292c2e;
                    border-width: 0 0 2px 2px;
                    pointer-events: none;
                    transform: rotate(-45deg);
                }
            }
        }
    }
    &:not(.select-search--multiple) {
        .select-search__select {
            position: absolute;
            z-index: 2;
            top: 44px;
            right: 0;
            left: 0;
            border-radius: 3px;
            overflow: auto;
            max-height: 160px;
        }
    }
}
/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
    }
}
/**
 * Input
 */
.select-search__input {
    width: 100%;
    border: 2px solid #aaa;
    height: 48px;
    font-size: 16px;
    line-height: 28px;
    color: #292c2e;
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    text-align: left;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    &::-webkit-search-decoration {
        -webkit-appearance: none;
    }
    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
    &::-webkit-search-results-button {
        -webkit-appearance: none;
    }
    &::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
    &:not([readonly]) {
        &:focus {
            cursor: initial;
        }
    }
}
/**
 * Options wrapper
 */
.select-search__select {
    background: $white;
    border: 2px solid #aaa;
    max-height: 200px;
}
/**
 * Options
 */
.select-search__options {
    list-style: none;
}

/**
 * Option row
 */
.select-search__row {
    &:not(:first-child) {
        border-top: 1px solid #eee;
    }
}

/**
 * Option
 */
.select-search__option {
    display: block;
    min-height: 44px;
    height: auto;
    width: 100%;
    padding: 5px 16px;
    background: $white;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-radius: 0;
    color: #292C2E;
    white-space: normal;
    &:not(.is-selected) {
        &:hover {
            background: rgba(86, 183, 247, 0.1);
        }
    }
}
.select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: $white;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}
.select-search--multiple {
    .select-search__option {
        height: 48px;
    }
    border-radius: 3px;
    overflow: hidden;
    .select-search__input {
        cursor: initial;
        border-radius: 3px 3px 0 0;
    }
    &:not(.select-search--search) {
        .select-search__input {
            cursor: default;
        }
    }
    .select-search__select {
        position: relative;
        overflow: auto;
        max-height: 260px;
        border-top: 1px solid #eee;
        border-radius: 0 0 3px 3px;
    }
}
.select-search__option.is-selected {
    background: $primary;
    color: $white;
    &:hover {
        background: $primary;
        color: $white;
    }
}
.select-search__option.is-highlighted {
    background: rgba(47, 204, 139, 0.1);
}
.select-search__option.is-highlighted.is-selected {
    background: #2eb378;
    color: $white;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}
.select-search.is-loading {
    .select-search__value {
        &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
            background-size: 11px;
        }
    }
}

.user-select {
    .select-search {
        width: 135px;
        input {
            border: 0;
            padding: 0 0 0 5px;
        }
    }
    .select-search__select {
        padding: 0;
        border-radius: 6px;
        width: 151px;
    }
    .select-search__option {
        border-radius: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #111111;
        position: relative;
        padding: 0 15px;
        min-height: 36px;
        text-transform: capitalize;
        &.is-selected {
            background: #56B7F7;
            color: #fff;
            &:before {
                display: none;
                content: "\e904";
                font-family: "ilmx" !important;
                position: absolute;
                right: 12px;
                top: 9px;
                font-size: 13px;
                color: #395B87;
            }
        }
        &:disabled {
            cursor: default;
            color: #aaa;
            pointer-events: none;
            background: #F2F2F2;
        }
        &:not(.is-selected):hover {
            background: #56B7F7;
            color: white;
        }
    }
    .select-search__row {
        border: 0 !important;
        margin-bottom: 0;
        border-top: 1px solid #AAAAAA !important;
        &:first-child {
            border-top: none;
        }
    }
}
.heading-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  0 0 26px;

    h3 {
        margin: 0 !important;
    }

    button {
        margin:  0 0 0 20px;
        height: 32px;
        font-size: 16px;
        padding:  0 15px;
        &:after {
            display: inline-block;
            vertical-align: middle;
            content: "\e904";
            font-family: "ilmx" !important;
            margin: -1px 0 0 8px;
            font-size: 14px;
        }
        &.cancel {
            color: #395B87;
            border: solid 1px #395B87;
            background: none;
            &:after {
                content: "\e958";
            }
            &:hover {
                border-color: #56B7F7;
                background-color: #56B7F7;
                color: #fff;
            }
        }
    }
}
@media (max-width: 767px) {
    .user-select {
        width: 100%;
        .select-search__select {
            width: 100%;
        }
        .select-search {
            width: 100%;
            input {
                border: 0;
                padding: 0 0 0 5px;
                height: 24px;
            }
        }
        .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
            top: 7px;
        }
        .select-search__select {
            margin-top: -10px;
        }
    }

}
@media (max-width: 575px) {
    .heading-holder {
        display: block;
        h3 {
            margin: 0 0 20px !important;
        }
        button {
            margin:  0 20px 0 0;
        }
    }
}

